import React, { memo, PropsWithChildren } from 'react';
import { ScreenComponentItem } from '@modules/learning/types/objects';

type BaseScreenComponentProps = PropsWithChildren<{
  item: ScreenComponentItem;
  className?: string;
  fullClassName?: string;
}>;

const BaseScreenComponent = memo(({
  item, children, className, fullClassName
}: BaseScreenComponentProps) => {
  return (
    <div className={fullClassName || `Screen__Component__Question ${className || ''}`}>
      <div className="Screen__Component__Question__Text">
        { item.parameters.text }
        {
          item.parameters.answer_required && (
            <span className="Screen__Component__Question__Text__Required">
              *
            </span>
          )
        }
      </div>
      {
        item.parameters.description && (
          <div className="Screen__Component__Question__Description">
            { item.parameters.description }
          </div>
        )
      }
      { children }
    </div>
  );
});

export default BaseScreenComponent;
