import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReceivedQuestionComponentNumber } from '@modules/learning/types/objects';
import { NumberInput } from '@common/components/form/inputs/number';
import BaseScreenComponent from './base-screen-component';

export type NumberScreenComponentValue = JSX.IntrinsicElements['input']['value'];

export type NumberScreenComponentProps = {
  item: ReceivedQuestionComponentNumber;
  onChange?: (n: string) => void;
  value?: NumberScreenComponentValue;
};

export const NumberScreenComponent = memo(({
  item,
  onChange,
  value
}: NumberScreenComponentProps) => {
  const { t } = useTranslation();
  return (
    <BaseScreenComponent item={item} className="NumberScreenComponent">
      <NumberInput
        value={value}
        allowDecimals={item.parameters.allow_decimals}
        placeholder={t('survey:question_type_number_input_placeholder')}
        onChange={onChange}
      />
    </BaseScreenComponent>
  );
});
