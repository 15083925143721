import TextAreaInput from '@common/components/form/inputs/textarea/textarea-input';
import { ReceivedQuestionComponentText } from '@modules/learning/types/objects';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseScreenComponent from './base-screen-component';

export type QuestionTextValue = string;

type QuestionTextProps = {
  item: ReceivedQuestionComponentText;
  value?: QuestionTextValue;
  onChange?: (newValue: string) => void;
};

const QuestionText = memo(({
  item,
  value = '',
  onChange
}: QuestionTextProps) => {
  const { t } = useTranslation();
  return (
    <BaseScreenComponent item={item} fullClassName="Screen__Component__QuestionText">
      <TextAreaInput
        rows={8}
        value={value}
        disabled={!onChange}
        onChange={onChange!}
        placeholder={t('learning:component_type_question_text_placeholder')}
      />
    </BaseScreenComponent>
  );

});

export default QuestionText;
