import React, { useCallback } from 'react';
import { ReceivedQuestionComponentBoolean }
  from '@modules/learning/types/objects';
import BaseScreenComponent from './base-screen-component';

export type BooleanScreenComponentValue = boolean | null;

type BooleanScreenComponentOwnProps = {
  item: ReceivedQuestionComponentBoolean;
  value?: BooleanScreenComponentValue;
  onChange?: (value: BooleanScreenComponentValue) => void;
};

export const BooleanScreenComponent = ({
  item,
  value,
  onChange
}: BooleanScreenComponentOwnProps) => {

  const onTrueToggle = useCallback(() => {
    if (onChange) {
      onChange(value === true ? null : true);
    }
  }, [onChange, value]);

  const onFalseToggle = useCallback(() => {
    if (onChange) {
      onChange(value === false ? null : false);
    }
  }, [onChange, value]);

  return (
    <BaseScreenComponent className="BooleanScreenComponent" item={item}>
      <div className="Screen__Component__BooleanButtons">
        <span
          className={value === true ? 'selected' : ''}
          onClick={onTrueToggle}
          role="button"
        >
          { item.parameters.label_true }
        </span>
        <span
          className={value === false ? 'selected' : ''}
          onClick={onFalseToggle}
          role="button"
        >
          { item.parameters.label_false }
        </span>
      </div>
    </BaseScreenComponent>
  );
};
