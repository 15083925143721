import * as React from 'react';
import Attachment from '../../../attachment';

const ComponentFile = ({ item }) => {
  if (!item.parameters || !item.parameters.attachment) return null;

  return (
    <div className="Screen__Component__File">
      <Attachment item={item.parameters.attachment} />
    </div>
  );
};

export default ComponentFile;
