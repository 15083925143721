import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { ReactSelectProps } from 'react-select';

import { Select } from '@common/components/form/inputs/select';
import { DateInput } from '@common/components/form/inputs/date';
import { SplitTimePicker } from '@common/components/form/inputs/split-time-picker';
import { useTimezonesData } from '@common/hooks/time';
import { getGMTOffsetLabel } from '@common/utils/time';

import type { ReceivedQuestionComponentDateTime } from '../../../../../modules/learning/types/objects';
import BaseScreenComponent from './base-screen-component';

export type DateTimeScreenValue = {
  date: Date | null;
  time: string | null;
  timezone: string;
};

type DateTimeScreenComponentOwnProps = {
  item: ReceivedQuestionComponentDateTime;
  value?: DateTimeScreenValue;
  timezone?: string;
  onTimeChange?: (value?: string) => void;
  onDateChange?: (date: Moment | null) => void;
  onTimezoneChange?: ReactSelectProps['onChange'];
  displayTimeInput?: boolean;
  displayChangeAction?: boolean;
};

export const DateTimeScreenComponent = ({
  item,
  value,
  timezone,
  onTimeChange,
  onDateChange,
  onTimezoneChange,
  displayTimeInput = false,
  displayChangeAction = true
}: DateTimeScreenComponentOwnProps) => {
  const { t } = useTranslation();

  // console.log('debug DateTimeScreenComponent item', item);
  const writeMode = !!(onDateChange && onTimeChange && onTimezoneChange);
  const timezonesData = useTimezonesData();
  const tzOptions = useMemo(() => {
    if (!timezonesData) return null;
    return timezonesData.map((tz) => {
      return {
        label: `${tz.name} (${getGMTOffsetLabel(tz.offset)})`,
        value: tz.name
      };
    });
  }, [timezonesData]);

  return (
    <BaseScreenComponent item={item} className="DateTimeScreenComponent">
      {
        item.parameters.allow_date_select && (
          <DateInput
            value={value?.date}
            onChange={onDateChange}
            disabled={!writeMode}
            arrowPosition={32}
            placeholder={
              item.parameters.allow_time_select && !displayTimeInput ?
                t('survey:question_type_date_time_input_placeholder') :
                t('survey:question_type_date_time_date_input_placeholder')
            }
          />
        )
      }

      {
        item.parameters.allow_time_select && displayTimeInput && (
          <SplitTimePicker
            value={value?.time || undefined}
            onChange={onTimeChange}
            allowNoSelection
            disabled={!writeMode}
            noSelectionLabel={t('common:no_value')}
            hoursPlaceholder={t('common:select_hours')}
            minutesPlaceholder={t('common:select_minutes')}
          />
        )
      }

      <div className="timezone">
        {
          writeMode && tzOptions ? (
            <>
              <small>{ t('forms:timezone') }</small>
              <Select
                value={timezone}
                options={tzOptions}
                onChange={onTimezoneChange}
                clearable={false}
              />
            </>
          ) : (
            <>
              <span>{ t('forms:timezone') }:</span>{' '}
              { timezone || <i>{ t('forms:user_timezone_value') }</i>}
              { displayChangeAction ? <a>{ t('common:change') }</a> : null }
            </>
          )
        }
      </div>
    </BaseScreenComponent>
  );
};
