import React from 'react';
import type { ReceivedQuestionComponentChecklist }
  from '../../../../../modules/learning/types/objects';
import { combineClassNames } from '@common/utils/combineClassNames';
import BaseScreenComponent from './base-screen-component';

export type ChecklistScreenComponentValue = string[];

type ChecklistScreenComponentOwnProps = {
  item: ReceivedQuestionComponentChecklist;
  onToggle?: (optionId: string) => void;
  value?: ChecklistScreenComponentValue;
};

export const ChecklistScreenComponent = ({
  item,
  onToggle,
  value
}: ChecklistScreenComponentOwnProps) => (
  <BaseScreenComponent item={item}>
    <div
      className={combineClassNames('Screen__Component__Question__CheckList', {
        'Screen__Component__Question__CheckList--read-only': !onToggle,
      })}
    >
      {item.parameters.options
        .filter((option) => option.value || option.value)
        .map((option) => (
          <div
            key={option.id}
            className="Screen__Component__Question__CheckList__Item"
            onClick={onToggle && (() => onToggle(option.id))}
            role="checkbox"
          >
            <div className="Screen__Component__Question__CheckList__Checkbox">
              {
                value && value.indexOf(option.id) > -1 && (
                  <div className="checked" />
                )
              }
            </div>
            <div className="Screen__Component__Question__CheckList__Text">
              {option.value}
            </div>
            {
              option.required && (
                <div
                  className="Screen__Component__Question__CheckList__Required"
                >
                  &nbsp;*
                </div>
              )
            }
          </div>
        ))}
    </div>
  </BaseScreenComponent>
);
